import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { VenomVersion } from 'client/data/models/version';
import { EdmundsAPI, CarCodeApi } from 'client/data/api/api-client';
import { withMetrics, CLIENT_ARTIFACT } from 'client/data/api/api-metrics';
import { getQuery } from 'client/utils/location';
import { encodeDealerLogicalName } from 'client/site-modules/dealerships/utils/logical-name';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

const WorkHours = PropTypes.shape({
  monday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  tuesday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  wednesday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  thursday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  friday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  saturday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  sunday: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string,
  }),
});

export const DealerInfoEntities = {
  DealerInfo: PropTypes.shape({
    name: PropTypes.string,
    showroomPhotos: PropTypes.arrayOf(PropTypes.object),
    videos: PropTypes.arrayOf(PropTypes.object),
    parentDealershipName: PropTypes.string,
    about: PropTypes.string,
    amenities: PropTypes.arrayOf(PropTypes.string),
    distinguishingFeatures: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        description: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    languages: PropTypes.arrayOf(PropTypes.string),
    socialLinks: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    sellYourCarUrl: PropTypes.string,
  }),
  WorkHours,
};

export const sellYourCarConfigParams = PropTypes.shape({
  sellYourCarUrl: PropTypes.string,
  dealerDomainName: PropTypes.string,
});

function getDealerInfoByName(response) {
  return response.json().then(responseJson => responseJson.results[0] || null);
}

export const getDealerInfoPath = vinData => {
  const rooftopId = get(vinData, 'dealerInfo.rooftopId');

  return rooftopId ? `dealerInfo.id.${rooftopId}` : null;
};

export const getDealerInfoNamePath = logicalName => `dealerInfo.name.${encodeDealerLogicalName(logicalName)}`;

export const getDealerPath = props => {
  const { logicalName } = props.params;
  const location = get(props, 'location', {});
  const locationId = getQuery(location).locationId;
  const idPath = locationId ? `dealerInfo.id.${locationId}` : '';
  return locationId ? idPath : getDealerInfoNamePath(logicalName);
};

export const DealerInfoModel = createModelSegment('dealerData', [
  /**
   * http://www.edmunds.com/api/dealer/v5/dealership/1234
   *
   * @returns DealerInfoEntities.DealerInfo
   */
  {
    path: 'dealerInfo.id.{dealerId}',
    resolve(match, context) {
      const dealerInfoUrl = `/dealer/v5/dealership/${match.dealerId}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(dealerInfoUrl)
        .catch(() => null);
    },
  },
  /**
   * http://www.edmunds.com/api/dealer/v5/dealerships?logicalname=HarborChevrolet_1&onlyactive=true
   *
   * @returns DealerInfoEntities.DealerInfo|null - returns null if the dealer does not exist - when we get
   *                                               a 404 from the service
   */
  {
    path: 'dealerInfo.name.{logicalName}',
    resolve(match, context) {
      const dealerInfoUrl = `/dealer/v5/dealerships?logicalname=${match.logicalName}&onlyactive=true`;
      return withMetrics(EdmundsAPI, context)
        .fetch(dealerInfoUrl)
        .then(getDealerInfoByName, () => null);
    },
  },
  {
    path: 'carcodeConfig.{rooftopId}',
    async resolve(match, context) {
      const useGatewayForCarcodeConfig = await context.resolveValue('useGatewayForCarcodeConfig', FeatureFlagModel);

      if (useGatewayForCarcodeConfig) {
        const carCodeUrl = `/carcode/v1/dealer/edmunds/${match.rooftopId}`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(carCodeUrl)
          .catch(() => {});
      }

      const carCodeUrl = `/carcode/v1/api/dealer/edmunds/${match.rooftopId}`;
      const venomVersion = await context.resolveValue('version', VenomVersion, false);

      try {
        return await CarCodeApi.fetchJson(carCodeUrl, {
          headers: {
            'x-artifact-id': CLIENT_ARTIFACT,
            'x-artifact-version': venomVersion,
          },
        });
      } catch (err) {
        return {};
      }
    },
  },
]);

import { IntelligentMessagingAPI } from 'client/data/api/api-client';
import { get } from 'lodash';
import { logger } from 'client/utils/isomorphic-logger';
import { ClientConfig } from 'client/configuration';

const MARKETO_API_URL = '/email/v1/marketo/campaigns/';

/**
 * Generates body for appraisal email marketo request.
 * @param {Object} data
 * @param {String} email
 * @returns {{body: string}}
 */
const getAppraisalEmailRequestBody = ({ data, email }) => ({
  body: JSON.stringify({
    email,
    tokens: {
      'my.extVars': JSON.stringify(data),
    },
  }),
});

const getEmailRequestBodyFromTokens = ({ data, email }) => ({
  // Replacing all null and undefined with empty string, since marketo fails on these values
  body: JSON.stringify({
    email,
    tokens: data,
  }).replace(/:(null|undefined)([,}])/g, ':\\"\\"$2'),
});

const MARKETO_CAMPAIGNS = {
  appraisal_email: {
    campaignId: 1657,
    getBody: getAppraisalEmailRequestBody,
  },
  offer_email: {
    campaignId: 3571,
    getBody: getAppraisalEmailRequestBody,
  },
  offer_renewal_email: {
    campaignId: 4543,
    getBody: getAppraisalEmailRequestBody,
  },
  test_appraisal_email: {
    campaignId: 3360,
    getBody: getAppraisalEmailRequestBody,
  },
  price_checker_promotion_2: {
    campaignId: 3688,
    getBody: getEmailRequestBodyFromTokens,
  },
  new_pricing_confirmation_email: {
    campaignId: 3560,
    getBody: getEmailRequestBodyFromTokens,
  },
  used_pricing_confirmation_email: {
    campaignId: 4004,
    getBody: getEmailRequestBodyFromTokens,
  },
  multi_offer_vac_confirmation_email: {
    campaignId: 4598,
    getBody: getEmailRequestBodyFromTokens,
  },
  multi_offer_kmx_confirmation_email: {
    campaignId: get(ClientConfig.get('marketoCampaignId'), 'multi_offer_kmx_confirmation_email', 5259),
    getBody: getAppraisalEmailRequestBody,
  },
  multi_offer_non_kmx_confirmation_email: {
    campaignId: get(ClientConfig.get('marketoCampaignId'), 'multi_offer_non_kmx_confirmation_email', 5260),
    getBody: getAppraisalEmailRequestBody,
  },
  appraisal_report_email_step: {
    campaignId: 5999,
    getBody: getAppraisalEmailRequestBody,
  },
  dealer_fu_email: {
    campaignId: 4763,
    getBody: getEmailRequestBodyFromTokens,
  },
  capital_one_lead_confirmation_email: {
    campaignId: 4879,
    getBody: getEmailRequestBodyFromTokens,
  },
  capital_one_consumer_email: {
    campaignId: 5050,
    getBody: getEmailRequestBodyFromTokens,
  },
  capital_one_prequal_term_email: {
    campaignId: 5180,
    getBody: getEmailRequestBodyFromTokens,
  },
  caramel_dealer_welcome: {
    campaignId: 5996,
    getBody: getEmailRequestBodyFromTokens,
  },
};

/**
 * Generates email request payload according to provided campaign name from MARKETO_CAMPAIGNS.
 * @param {Object} data
 * @param {String} campaignName
 * @param {String} email
 * @returns {Object}
 */
const getMarketoRequestPayload = ({ data, campaignName, email }) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  ...MARKETO_CAMPAIGNS[campaignName].getBody({ data, email }),
  retries: 0,
});

/**
 * Send a request to marketo email api with appropriate data.
 * @param {Object} data
 * @param {String} campaignName
 * @param {String} email
 * @returns {Promise}
 */
export function sendMarketoEmailReport({ data, campaignName, email }) {
  const campaignId = MARKETO_CAMPAIGNS[campaignName].campaignId;
  const payload = getMarketoRequestPayload({ data, campaignId, campaignName, email });

  return IntelligentMessagingAPI.fetch(`${MARKETO_API_URL}${campaignId}`, payload).catch(err => {
    logger('error', err);
  });
}

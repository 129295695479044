import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import uuidv4 from 'uuid/v4';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { EvaApi, WidgetStoreApi, EdmundsAPI } from 'client/data/api/api-client';
import { RequestModel } from 'client/data/models/request';
import { logger } from 'client/utils/isomorphic-logger';
import {
  ICO_WIDGET_STORE_TYPE_NAME,
  NUM_ICO_DEALERS,
} from 'site-modules/shared/components/dealer-ico-widget/constants';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

export const DealerIcoWidgetEntities = {
  Configs: PropTypes.shape({
    isActive: PropTypes.bool,
    loadParams: PropTypes.objectOf(PropTypes.any),
  }),
};

export function buildDealerIcoWidgetConfigurationPath({ rooftopId }) {
  return rooftopId ? `configuration.rooftopId["${rooftopId}"].domain.edmunds` : '';
}

export function buildClosestIcoDealersPath({ zipCode, numIcoDealers }) {
  return zipCode
    ? `tradeInOffers.dealers.zipCode["${zipCode}"].numIcoDealers["${numIcoDealers || NUM_ICO_DEALERS}"]`
    : '';
}

export function removeProdSubdomain(fqDomain) {
  return fqDomain.replace(/^(prod-(\d\d-)?)?www\./i, '');
}

export const DealerIcoWidgetModel = createModelSegment('dealerIcoWidget', [
  /**
   * https://widgetstore.edmunds.com/api/extension/v1/configuration/dealer/678901/domain/edmunds.com
   */
  {
    path: 'configuration.rooftopId["{rooftopId}"].domain.edmunds',
    async resolve({ rooftopId }, context) {
      const CURRENT_EDMUNDS_FQ_DOMAIN = await context.resolveValue('hostName', RequestModel);
      // Removes PROD prefix (www, etc.) of FQDN (if applicable), as it's not allowed by Widget Store:
      const dealerDomainConfigEndpointPath = `/extension/v1/configuration/dealer/${rooftopId}/domain/${removeProdSubdomain(
        CURRENT_EDMUNDS_FQ_DOMAIN
      )}`;

      try {
        const useGatewayForIcoConfig = await context.resolveValue('useGatewayForIcoConfig', FeatureFlagModel);
        const apiClient = useGatewayForIcoConfig ? EdmundsAPI : WidgetStoreApi;
        const dealerDomainConfigResponse = await withMetrics(apiClient, context).fetchJson(
          dealerDomainConfigEndpointPath
        );

        let activeIcoWidgetConfig;
        dealerDomainConfigResponse.find(({ active, widgets }) => {
          if (!active || !Array.isArray(widgets)) {
            return false;
          }
          activeIcoWidgetConfig = widgets.find(
            ({ active: activeWidget, type }) => activeWidget && type === ICO_WIDGET_STORE_TYPE_NAME
          );
          return activeIcoWidgetConfig;
        });

        return {
          isActive: !!activeIcoWidgetConfig,
          loadParams: get(activeIcoWidgetConfig, 'loadParams', {}),
        };
      } catch (e) {
        logger(
          'error',
          `Unable to get ICO configuration from Widget Store (Extension) API for Dealer Rooftop ID '${rooftopId}' of FQDN '${CURRENT_EDMUNDS_FQ_DOMAIN}'. Error:`,
          e
        );
        return {
          hasError: true,
        };
      }
    },
  },

  {
    path: 'tradeInOffers.dealers.zipCode["{zipCode}"].numIcoDealers["{numIcoDealers}"]',
    async resolve({ zipCode, numIcoDealers }, context) {
      // noCache param is added to prevent caching the GET request outside the Venom
      const noCacheParam = `noCache=${uuidv4()}`;
      const zipCodeParam = `zipCode=${zipCode}`;
      const numIcoDealersParam = `numIcoDealers=${numIcoDealers}`;
      const closestIcoMultiDealersPath = `/trade-in-offers/dealers?${zipCodeParam}&${numIcoDealersParam}&${noCacheParam}`;
      try {
        const closestIcoMultiDealers = await withMetrics(EvaApi, context).fetchJson(closestIcoMultiDealersPath);
        return isEmpty(closestIcoMultiDealers) ? {} : closestIcoMultiDealers;
      } catch (e) {
        logger(
          'error',
          `Unable to get the closest ${numIcoDealers} ICO Dealer(s) (EVA API) by ZIP Code '${zipCode}'}'. Error:`,
          e
        );
        return {};
      }
    },
  },
]);
